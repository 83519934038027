import React from 'react';
import { SolveForX } from './SolveForX';

function App() {
  return (
    <div className="App">
      <SolveForX />
    </div>
  );
}

export default App;