import React, { useState, useEffect } from 'react';

const generateEquation = () => {
  const a = Math.floor(Math.random() * 10) + 1;
  const b = Math.floor(Math.random() * 10) + 1;
  const c = a * b;
  return { equation: `${a} * x = ${c}`, solution: b };
};

export const SolveForX = () => {
  const [equation, setEquation] = useState('');
  const [solution, setSolution] = useState(0);
  const [guess, setGuess] = useState('');
  const [message, setMessage] = useState('');
  const [attempts, setAttempts] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [solvedCount, setSolvedCount] = useState(0);

  useEffect(() => {
    newGame();
  }, []);

  const newGame = () => {
    const { equation, solution } = generateEquation();
    setEquation(equation);
    setSolution(solution);
    setGuess('');
    setMessage('');
    setAttempts(0);
    setGameOver(false);
    setSolvedCount(Math.floor(Math.random() * 1000));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userGuess = parseFloat(guess);
    
    if (isNaN(userGuess) || !Number.isFinite(userGuess)) {
      setMessage('Please enter a valid number.');
      return;
    }

    setAttempts(attempts + 1);

    if (userGuess === solution) {
      setMessage('Correct! You solved the equation.');
      setGameOver(true);
      setSolvedCount(solvedCount + 1);
    } else {
      if (attempts >= 3) {
        setMessage(`Game over. The correct answer was ${solution}.`);
        setGameOver(true);
      } else {
        setMessage(userGuess < solution ? 'Too low. Try again.' : 'Too high. Try again.');
      }
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '40px auto', padding: '20px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px', textAlign: 'center' }}>Solve for x</h1>
      <div style={{ fontSize: '18px', marginBottom: '16px', textAlign: 'center' }}>{equation}</div>
      <form onSubmit={handleSubmit} style={{ marginBottom: '16px' }}>
        <input
          type="text"
          value={guess}
          onChange={(e) => setGuess(e.target.value)}
          style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '8px' }}
          placeholder="Enter your answer for x"
          disabled={gameOver}
        />
        <button
          type="submit"
          style={{ width: '100%', padding: '8px', backgroundColor: '#3B82F6', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
          disabled={gameOver}
        >
          Submit Answer
        </button>
      </form>
      {message && (
        <div style={{ padding: '12px', backgroundColor: gameOver ? '#E5E7EB' : '#FEE2E2', borderRadius: '4px', marginBottom: '16px' }}>
          {message}
        </div>
      )}
      <div style={{ textAlign: 'center' }}>
        <p>Attempts: {attempts}/4</p>
        {gameOver && (
          <button
            onClick={newGame}
            style={{ padding: '8px 16px', backgroundColor: '#10B981', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer', marginTop: '8px' }}
          >
            Play Again
          </button>
        )}
      </div>
      <div style={{ marginTop: '16px', textAlign: 'center', fontSize: '14px', color: '#6B7280' }}>
        Number of people who solved this puzzle: {solvedCount}
      </div>
    </div>
  );
};